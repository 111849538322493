import React, { useEffect, useState } from "react";
import {
    Table,
    InfoSection,
    TableModal,
    NavigationTabs,
    Loader
} from "pubtrack-frontend-library";
import { getEligibilities, getEligibilityDetails, getEligibilityTermination, getHistoricEligibilities } from "../../helpers/eligibilities";
import { eligibilityDetailsColumns, historicEligibilitiesColumns, responseEligibilitiesColumns, inProcessEligibilitiesColumns } from './columnsData'

const Eligibility = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [eligibilityDetails, setEligibilityDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [eligibilities, setEligibilities] = useState([]);
    const [historicEligibilities, setHistoricEligibilities] = useState([]);
    const [messageId, setMessageId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const manuscriptId = eligibilityDetails?.manuscript || "-";
    const title = eligibilityDetails?.title || "-";
    const journal = eligibilityDetails?.journal || "-";

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getEligibilities(),
            getHistoricEligibilities()
        ])
            .then(([eligibilityRes, historicEligibilityRes]) => {
                setEligibilities(eligibilityRes.data);
                setHistoricEligibilities(historicEligibilityRes.data);
            })
            .catch(() => setError(true))
            .finally(() => {
                setIsLoading(false);
            });

    }, []);

    const onSelectRow = row => {
        const messageId = row && row.original ? (row.original.article_id || row.original.id) : null;
        setSelectedMessage(messageId);
        setMessageId(messageId);
    };

    const tabs = [
        {
            id: '1',
            title: 'In progress',
            content: (
                <Table
                    data={eligibilities.pending || []}
                    columns={inProcessEligibilitiesColumns}
                    onSelectRow={onSelectRow}
                />
            ),
        },
        {
            id: '2',
            title: 'Historic',
            content: (
                <Table
                    data={historicEligibilities}
                    columns={historicEligibilitiesColumns}
                    onSelectRow={onSelectRow}
                />
            ),
        },
        {
            id: '3',
            title: 'Accepted',
            content: (
                <Table
                    data={eligibilities.accepted || []}
                    columns={responseEligibilitiesColumns}
                />
            ),
        },
        {
            id: '4',
            title: 'Rejected',
            content: (
                <Table
                    data={eligibilities.rejected || []}
                    columns={responseEligibilitiesColumns}
                />
            ),
        },
    ];

    useEffect(() => {
        if (selectedMessage) {
            setIsOpen(true);

            return getEligibilityDetails(selectedMessage)
                .then(res => setEligibilityDetails(...res.data?.header))
        }
    }, [selectedMessage]);

    const getEligibilityDetailsData = () => {
        setSelectedMessage(null);

        return getEligibilityDetails(selectedMessage)
            .then(res => ({ data: res.data.flow }))
    };

    const onTermination = () => {
        if (messageId) {
            return getEligibilityTermination(messageId);
        };
    };


    return (
        <div className="container">
            <div className="my-5">
                <div className="my-5">
                    {isLoading && <Loader />}
                    {!isLoading && !error && <NavigationTabs tabs={tabs} />}
                </div>

                <TableModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    columns={eligibilityDetailsColumns}
                    getTableData={getEligibilityDetailsData}
                    search={false}
                    itemsPerPage={null}
                    showTerminationBtn={true}
                    onTermination={onTermination}
                    content={<div className="mb-5">
                        <h5 className="text-center mb-4 mt-4">Eligibility workflow</h5>
                        <InfoSection title="Manuscript ID" info={manuscriptId} />
                        <InfoSection title="Title" info={title} />
                        <InfoSection title="Journal" info={journal} />
                    </div>}
                />
            </div>
        </div>
    );
};


export default Eligibility;