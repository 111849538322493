import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
  ...theme,

  primaryColor: '#043c72',
  secondaryColor: '#0c2041',

  accordionHeaderBackgroundColor: '#043c72',
  modalHeaderBackgroundColor: '#043c72',
  tabsBackgroundColor: "#043c72",
  tabActiveColor: "#fff",
  tabItemColor: "#8ea0b1",

  navbarLogoBackgroundColor: '#043c72',
  navbarBackgroundColor: '#043c72',
  navbarButtonHoverColor: '#0c2041',
  navbarButtonTextColor: '#fff',
  navbarButtonActiveTextColor: "#fff",
  navbarFontWeight: 600,
  navbarTextTransform: "uppercase",
  navbarButtonHoverTextColor: "#fff",

  logoutButtonBackgroundColor: '#fff',
  logoutButtonTextColor: "#043c72",

  paginationButtonBackgroundColor: '#043c72',
  paginationButtonHoverBackgroundColor: '#0c2041',

  tableHeaderBackgroundColor: '#043c72',

  searchButtonBackgroundColor: '#0c2041',
  defaultButtonBackgroundColor: '#0c2041',
  primaryButtonBackgroundColor: '#0c2041',
  colorsPalette: [
    "#002242", "#144773", "#346ea4", "#4e8ab8", "#6faed2", "#9ecbe3"
  ]
}

const App = () => (
  <ThemeProvider theme={customTheme}>
    <GlobalStyles />
    <Routes />
    <Footer />
  </ThemeProvider>
);

export default App;