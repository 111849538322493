import { MDBBtn } from 'mdbreact'
import { transformDate } from 'pubtrack-frontend-library'

const customBtnStyles = {
    display: 'block',
    padding: 0,
    margin: 0,
    textTransform: 'lowercase'
};

const manuscriptStyles = {
    marginBottom: '10px'
};

const ArticleIDsCellContent = ({ row }) => {
    const handleButtonClick = (e) => {
        e.stopPropagation();
        window.open(row.original?.doi, '_blank');
    };

    return (
        <>
            {row?.original?.doi &&
                <MDBBtn style={customBtnStyles} color='link' onClick={handleButtonClick}>
                    {row.original.doi}
                </MDBBtn>}
            {row?.original?.manuscript && <span style={manuscriptStyles}>{row.original.manuscript}</span>}
        </>
    );
}

export const messagesColumns = [
    {
        Header: 'ID',
        accessor: 'id',
        sortable: true
    },
    {
        Header: 'Created',
        Cell: ({ row }) =>
            <span>{transformDate(row.original.created)}</span>,
        sortable: true
    },
    {
        Header: 'Type',
        accessor: 'type',
        sortable: true,
    },
    {
        Header: 'Sent to',
        accessor: 'sentto',
        sortable: true
    },
    {
        Header: 'Article IDs',
        Cell: ArticleIDsCellContent,
        sortable: true
    },
    {
        Header: 'State',
        accessor: 'state',
        sortable: true
    },
]
